import React, {useState, useContext, useEffect} from 'react';
import {Container, Row, Col, Card, Form} from 'react-bootstrap';
import "./Tenure.scss"
import ProductAccordion from '../ProductAccordion/ProductAccordion';
import {ProductContext} from "../../Helper/Context/ProductContext";
import Api from "../../Helper/api";
import AlertMessage from '../AlertMessage/AlertMessage';
export default function Tenure({plan}) {
    const [discount,
        setDiscount] = useState("")
    const [tenureInfo,setTenureInfo] = useState(null)
    const [showAlert,
        setAlert] = useState(false);
    const [alertMessage,
        setAlertMessage] = useState("");
    const [tenureRecommendations,
        setTenureRecommendations,setMoreRecommendations] = useContext(ProductContext);

    function showDiscount(e,questionAnswerPresent,mysteryBox,planName) {
        const answers = JSON.parse(window.localStorage.answersData)
        if (answers === null && questionAnswerPresent === true) {
            setAlert(true);
            setAlertMessage("Please Answer the questions to continue");
        } else {
            let tenureId = e.target.value.split("-")[2]
            if (e.target.value.split("-")[1] === undefined) {
                setDiscount("")
            } else {
                setDiscount("Save " + e.target.value.split("-")[1] + "% when you select " + e.target.value.split("-")[0] + " Months.")
                setTenureInfo(e.target.value.split("-"))
            }
            gotoRecommendations();
            if (window.localStorage.answersData !== 'null') {
                const answersObj = JSON.parse(window.localStorage.answersData)
                const answersData = Object.values(answersObj).join(',')
                fetchRecommendedProducts(tenureId, encodeURIComponent(answersData))
            } else if (mysteryBox === false){
                const answersData = "Freedom Plan"
                fetchRecommendedProducts(tenureId, answersData)
            } else {
                const mysteryBoxData = "Mystery Box"
                fetchRecommendedProducts(tenureId, mysteryBoxData)
            }
        }
    }

    const fetchRecommendedProducts = (tenure, answers) => {
        const api = new Api();
            api
            .getRecommendedProducts(tenure, answers)
            .then((response) => {
                setTenureRecommendations(response.data.recommendations)
                setMoreRecommendations(response.data.extra_recommended_products)
            })
            .catch((error) => error)
    }

    const gotoRecommendations = () => {
        const divElement = document.getElementById('qna-section');
        divElement.scrollIntoView({ behavior: 'smooth', block: "start" });
    }

    useEffect(() => {
        window
            .localStorage
            .setItem("answersData", null);
        return () => {}
    }, [plan])

    return (
        <div>
            <Container className='tenure-container' id="showplan-section">
                <AlertMessage setAlert={setAlert} showAlert={showAlert} message={alertMessage} variant="primary"/>
                <h3 style={{
                    textAlign: "center"
                }} >TENURE OF SUBSCRIPTION</h3>
                <div className="justify-content-xxs-center">
                    <div
                        className="tenure-center me-0">
                        <Card
                            style={{
                            width: '18rem'
                        }}
                            className="tenure-card mx-auto">
                            <label>
                                <input type="radio" name="tenure" className="tenure-input-element"/>
                                <Card.Body className="tenurebox" id={`planbox-`}>
                                    <Card.Title className="plan-title">{plan["name"]}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">Free Delivery</Card.Subtitle>
                                    <Card.Text>
                                        {plan["description"]}
                                    </Card.Text>
                                    <div className="d-flex">
                                        <div className="align-self-center mx-auto">
                                            <Form.Select
                                                disabled = {false}
                                                aria-label="Default select example"
                                                className="rounded-pill"
                                                onChange={(e) => showDiscount(e,plan["question_answer_present"],plan["mystery_box"],plan["name"])}>
                                                <option key={plan["tenures"][0]["id"]} >Pick A Tenure</option>
                                                {plan["tenures"].map((tenure) => (
                                                    <option
                                                        key={tenure["id"]}
                                                        value={`${tenure["duration"]}-${tenure["discount"]}-${tenure["id"]}-${tenure["price"]}`}>{tenure["duration"]} Months</option>
                                                ))
                                                }
                                            </Form.Select>
                                            <span className="discount-text">{discount}</span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </label>
                        </Card>
                    </div>
                </div>
            </Container>
            <br id='qna-section'/>
            <br/>
            <div>
                {discount !== ""
                    ? <ProductAccordion tenureInfo={tenureInfo} mysteryBox={plan.mystery_box}/>
                    : null
                }
            </div>
        </div>
    );
}