import React, {createContext, useState} from 'react';
export const ProductContext = createContext();

export const AddProductContext = createContext();

export const ProductProvider = props => {

    const [tenureRecommendations,
        setTenureRecommendations] = useState([])
    const [moreRecommendations,
        setMoreRecommendations] = useState([])

    // const [isLoading,setIsLoading] = useState(true) Loading TODO
    return (
        <ProductContext.Provider
            value={[tenureRecommendations, setTenureRecommendations, setMoreRecommendations]}>
            <AddProductContext.Provider
                value={[moreRecommendations]}>
                {props.children}
            </AddProductContext.Provider>
        </ProductContext.Provider>
    );
}