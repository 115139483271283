import React,{useState,Fragment,useContext} from 'react'
import Api from "../../Helper/api";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {ProductContext} from "../../Helper/Context/ProductContext";
import AlertMessage from '../AlertMessage/AlertMessage';

export default function SearchProduct({tenureId,setProduct}) {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [showAlert,
        setAlert] = useState(false);
    const [alertMessage,
        setAlertMessage] = useState("");
    const close = () => setProduct(false);

    const [tenureProducts,
        setTenureProducts] = useContext(ProductContext);

    const handleSearchProducts = (query) => {
        setIsLoading(true);
        const api = new Api();
        api.searchProducts(query)
        .then((response) => {
            const products = response.data.products
            const options = products.map((i) => ({
                id: i.shopify_product_id,
                title: `${i.title} ${i.variants[0].title}`,
                image: i.image,
                productsObj: i,
                price: i.variants[0].price
            }));
            setOptions(options);
            setIsLoading(false);
            }
        )
        .catch((error) => error)
    }

    const addProduct = (product,tenureId) => {
        const products = tenureProducts.find((tenure) => tenure.tenure_index === tenureId).products;
        const newProduct = products.map((product) => product.shopify_product_id).includes(product["shopify_product_id"]);

            if (products.length === 3) {
                setAlert(true);
                setAlertMessage("Only 3 Products allowed");
            } else {
                if (newProduct) {
                    setAlert(true);
                    setAlertMessage("Product already addded");
                } else {
                    setTenureProducts((prevtenureProducts) => {
                        let newPreviousTenures = prevtenureProducts.map((tenure) => tenure.tenure_index === tenureId
                            ? {
                                ...tenure,
                                products: [
                                    ...tenure.products,
                                    product
                                ]
                            }
                            : tenure);
                        return newPreviousTenures;
                    });
                    close();
                }
            }
    }

    const filterBy = () => true;

  return (
      <div>
          <AlertMessage setAlert={setAlert} showAlert={showAlert} message={alertMessage} variant="primary"/>
          <AsyncTypeahead
           filterBy={filterBy}
           id="async-example"
           isLoading={isLoading}
           labelKey="title"
           minLength={4}
           onSearch={handleSearchProducts}
           options={options}
           placeholder="Search for Teas"
           renderMenuItemChildren={(option, props) => (
             <Fragment>
               <img
                 alt={option.title}
                 src={option.image}
                 style={{
                   height: '24px',
                   marginRight: '10px',
                   width: '24px',
                 }}
               />
               <span onClick={() => addProduct(option.productsObj,tenureId)}>{option.title} <span style={{color: "green"}}>INR {option.price}</span>  </span>
             </Fragment>
           )}
         />
      </div>
  )
}