import React from "react";
import { Breadcrumb } from "react-bootstrap";
import "./Breadcrumb.scss";
function TopBeadcrumb() {
	return (
		<Breadcrumb>
			<Breadcrumb.Item href="https://teamonk.com">Home</Breadcrumb.Item>
			<Breadcrumb.Item active>Subscribe & Save</Breadcrumb.Item>
		</Breadcrumb>
	);
}

export default TopBeadcrumb;
