import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import React from "react";
import "./Navbar.scss";
import { PUBLIC_IMAGE_PATH } from "../utils/Constants";
import Card from "../Card/Card";
import Breadcrumb from "..//Breadcrumb/Breadcrumb";
import {SubscribeProvider} from "../../Helper/Context/ShowSubscribeNow";
function TopNavbar() {
	return (
		<SubscribeProvider>
		<Navbar
			collapseOnSelect
			expand="lg"
			bg="light"
			variant="light"
			sticky="top"
		>
			<Container fluid>
				<Navbar.Brand href="#home">
					{" "}
					<img
						src={PUBLIC_IMAGE_PATH + "logo1.png"}
						width="180px"
						height="45px"
						alt="Logo"
					/>{" "}
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="mx-auto">
						<NavDropdown
							title="Shop Now"
							id="collasible-nav-dropdown"
						>
							<NavDropdown.Item href="https://teamonk.com/collections/green-teas">
								Green Teas
							</NavDropdown.Item>
							<NavDropdown.Item href="https://teamonk.com/collections/black-teas">
								Black Teas
							</NavDropdown.Item>
							<NavDropdown.Item href="https://teamonk.com/collections/all">
								Oolang Teas
							</NavDropdown.Item>
							<NavDropdown.Item href="https://teamonk.com/collections/all">
								Herbal Infusion
							</NavDropdown.Item>
							<NavDropdown.Item href="https://teamonk.com/collections/all">
								White Tea
							</NavDropdown.Item>
							<NavDropdown.Item href="https://teamonk.com/collections/tea-gift-sets-online">
								Gifting and Accessoires{" "}
							</NavDropdown.Item>
						</NavDropdown>
						<Nav.Link href="https://teamonk.com/apps/tracktor/track">Track Order</Nav.Link>
						<Nav.Link href="https://teamonk.com/pages/swym-wishlist">Wishlist</Nav.Link>
						<NavDropdown
							title="About Us"
							id="collasible-nav-dropdown"
						>
							<NavDropdown.Item href="https://teamonk.com/pages/why-teamonk">
								Why Teamonk
							</NavDropdown.Item>
							<NavDropdown.Item href="https://teamonk.com/pages/teamonk-story">
								Teamonk Story
							</NavDropdown.Item>
							<NavDropdown.Item href="https://teamonk.com/pages/our-team">
								Our Team
							</NavDropdown.Item>
							<NavDropdown.Item href="https://teamonk.com/blogs/press">Media</NavDropdown.Item>
						</NavDropdown>
						<NavDropdown
							title="Know Your Tea"
							id="collasible-nav-dropdown"
						>
							<NavDropdown.Item href="https://teamonk.com/blogs/tea-science/history-of-tea">
								History Of Tea
							</NavDropdown.Item>
							<NavDropdown.Item href="https://teamonk.com/blogs/tea-science/types-of-tea">
								Types Of Tea
							</NavDropdown.Item>
							<NavDropdown.Item href="https://teamonk.com/blogs/tea-science/types-of-tea-flushes">
								Types Of Flushes
							</NavDropdown.Item>
							<NavDropdown.Item href="https://teamonk.com/blogs/tea-science/benefits-of-drinking-tea">
								Benefits Of Drinking Tea
							</NavDropdown.Item>
							<NavDropdown.Item href="https://teamonk.com/blogs/tea-science/science-of-tea">
								Science Of Tea
							</NavDropdown.Item>
							<NavDropdown.Item href="https://teamonk.com/blogs/tea-science/legends-of-tea">
								Legends Of Tea
							</NavDropdown.Item>
						</NavDropdown>
						<Nav.Link href="https://teamonk.com/pages/catalog">Download Catalog</Nav.Link>
					</Nav>
				</Navbar.Collapse>

				<Nav className="ml-auto">
					{/* <Nav.Link href=""> <img className="gcs-head-icons" src="https://cdn.shopify.com/s/files/1/0059/5525/6393/files/wishlist.png?v=1615792454" alt="wiishlist"/></Nav.Link>
                <Nav.Link eventKey={2} href="">
                <img className="gcs-head-icons" src="https://cdn.shopify.com/s/files/1/0059/5525/6393/files/Home-Page_0000s_0176_pngegg.png?v=1615173239" alt="cart"/>
                </Nav.Link> */}
				</Nav>
			</Container>
		</Navbar>
			<Breadcrumb/>
			<Card/>	
		</SubscribeProvider>
	);
}

export default TopNavbar;
