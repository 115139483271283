import React, {useState, useEffect,useCallback} from 'react';
import {Container} from 'react-bootstrap';
import Tenure from '../Tenure/Tenure';
import * as Survey from "survey-core";
import * as SurveyReact from "survey-react-ui";
import "survey-core/defaultV2.css";
import Api from '../../Helper/api';
import "./QnA.scss";
Survey
    .StylesManager
    .applyTheme("defaultV2");

    export default function QnA({plan, groupId}) {
        const [questions,
            setQuestions] = useState({})
        const [answers,setAnswers] = useState(window.localStorage.answersData)
        useEffect(() => {
            getQuestionsAndAnswers(plan, groupId)
            window
                .localStorage
                .setItem("answersData", null);
            return () => {}
        }, [plan,groupId])
    
        const getQuestionsAndAnswers = (plan, groupId) => {
            const planId = plan.id
            const api = new Api();
            api
                .getQna(groupId, planId)
                .then((response) => {
                    setQuestions(
                        {
                            "showQuestionNumbers": "off",
                            "elements": response.data
                        }
                    )
                })
                .catch((error) => error)
        }
    
        const qna = {
            "showQuestionNumbers": "off",
            "elements": [
                {
                    "type": "radiogroup",
                    "name": "relationship",
                    "title": "How is your relationship with tea?",
                    "isRequired": true,
                    "choices": [
                        "Cardamom", "Chamomile", "Clove", "Ginger"
                    ],
                    "colCount": 4
                }, {
                    "type": "radiogroup",
                    "name": "drinkTime",
                    "title": "When do you drink your tea?",
                    "visibleIf": "{relationship}='Cardamom' or {relationship}='Chamomile' or {relationship}='Clove" +
                            "' or {relationship}='Ginger'",
                    "isRequired": true,
                    "choices": [
                        "Morning", "Afternoon", "Evening", "Night"
                    ],
                    "colCount": 4
                }, {
                    "type": "radiogroup",
                    "name": "goals",
                    "title": "What health goals are you running towards?",
                    "visibleIf": "{drinkTime}= 'Morning' or {drinkTime}= 'Afternoon' or {drinkTime}= 'Evening' or " +
                            "{drinkTime}= 'Night'",
                    "isRequired": true,
                    "choices": [
                        "FatLoss", "ImuneBooster", "FreshFeel"
                    ],
                    "colCount": 4
                }, {
                    "type": "radiogroup",
                    "name": "falvours",
                    "title": "What falvours do you enjoy?",
                    "visibleIf": "{goals}= 'FatLoss' or {goals}= 'ImuneBooster' or {goals}= 'FreshFeel' ",
                    "isRequired": true,
                    "choices": [
                        "Lemon", "Ginger", "Rose", "Herbal"
                    ],
                    "colCount": 4
                }
            ]
        }
        const survey = new Survey.Model(questions);
        // const survey = new Survey.Model(qna);
        const scrollOne = (sender) => {
            const divElement = document.getElementById('tenure-section');
            divElement.scrollIntoView({ behavior: 'smooth', block: "start" });
            // setAnswers(sender.data)
        }
        const goToTenure = useCallback((sender) => {
            sender.clear(false);
            sender.mode = "display";
            saveSurveyData(sender)
            scrollOne(sender);
        }, []);

        function saveSurveyData(survey) {
            var data = survey.data;
            window
                .localStorage
                .setItem("answersData", JSON.stringify(data));
        }
          
        survey.onComplete.add(goToTenure)
        return (
            <div >
                <Container >
                    <br/>
                    <h3 style={{
                        textAlign: "center"
                    }}>Answer Our Few Questions</h3>
                    <br/>
                        <SurveyReact.Survey model={survey} />
                </Container>
                <br id="tenure-section" />
                <br/>
                <br/>
                <br/>
                <div>
                    <Tenure plan={plan}/>
                </div>
                {/* {JSON.parse(window.localStorage.showTenure)
                    ? <Tenure plan={plan}/>
                    : null} */}
            </div>
        );
    }
