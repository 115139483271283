import axios from "axios";

export default class Api {
    constructor() {
        this.api_url = process.env.REACT_APP_API_ENDPOINT;
    }

    init = () => {
        let headers = {
            Accept: "application/json"
        };

        this.client = axios.create({baseURL: this.api_url, headers: headers});
        return this.client;
    };

    getGroupInfo = (id) => {
        return this
            .init()
            .get(`/groups/${id}`);
    };

    getPlan = (planId, groupId) => {
        return this
            .init()
            .get(`/groups/${groupId}/plans/${planId}`);
    };

    getQna = (groupId,planId) => {
        return this
            .init()
            .get(`/groups/${groupId}/plans/${planId}/questions`)
    }

    getRecommendedProducts = (tenure, answers) => {
        return this
            .init()
            .get(`/products/recommendations?tenure_id=${tenure}&answers=${answers}`)
    }

    searchProducts = (query) => {
        return this
            .init()
            .get(`/products/search?query=${query}`)
    }

    checkout = (checkoutBody) => {
        return this
            .init()
            .post(`/checkouts`,checkoutBody)
    }
}
