import React, {useState, useContext} from "react";
import "./ShowProduct.scss";
import {Button, Col, Row} from "react-bootstrap";
import AlertMessage from "../AlertMessage/AlertMessage";
import {ProductContext} from "../../Helper/Context/ProductContext";
import { IoAddCircle } from 'react-icons/io5';
import { IoMdRemoveCircle } from 'react-icons/io';
import ProductModal from "../ProductModal/ProductModal";
import { PUBLIC_IMAGE_PATH } from "../utils/Constants";
export default function ShowProduct({tenureId,mysteryBox}) {
    const [addProduct,
        setProduct] = useState(false);
    const [tenureRecommendations,
        setTenureRecommendations] = useContext(ProductContext);
    const [showAlert,
        setAlert] = useState(false);
    const [alertMessage,
        setAlertMessage] = useState("");

    const products = tenureRecommendations.find((item) => item.tenure_index === tenureId).products;
    // const totalPrice = products.map(({quantity, variants}) => {return quantity * variants[0].price}).reduce((price1, price2) => price1 + price2, 0);
    

    // const totalQuantity = products.map(({quantity}) => {
    //     return quantity;
    // }).reduce((quantity1, quantity2) => quantity1 + quantity2, 0);

    const increaseQuantity = (product_id, quantity,mysteryBox) => {
        setAlert(false);
        let productQuantity = quantity;
        let productId = product_id
        if (mysteryBox) {
            setAlert(true);
            setAlertMessage("Quantity cant be increased with Mystery Box Plan");
        } else {  
            setTenureRecommendations((previousTenures) => {
                let newPreviousTenures = previousTenures.map((tenure) => tenure.tenure_index === tenureId
                    ? {
                        ...tenure,
                        products: tenure
                            .products
                            .map((product) => product.shopify_product_id === productId
                                ? {
                                    ...product,
                                    quantity: productQuantity + 1
                                }
                                : product)
                    }
                    : tenure);
                return newPreviousTenures;
            });
        }
    };

    const decreaseQuantity = (product_id, quantity,mysteryBox) => {
        let productQuantity = quantity;
        let productId = product_id
        
       
        if (productQuantity <= 1) {
            if (mysteryBox) {
                setAlert(true);
                setAlertMessage("Product cant be removed with Mystery Box Plan");
            } else {     
                removeProduct(productId);
                setAlert(true);
            }
        } else {
            setTenureRecommendations((previousTenures) => {
                let newPreviousTenures = previousTenures.map((tenure) => tenure.tenure_index === tenureId
                    ? {
                        ...tenure,
                        products: tenure
                            .products
                            .map((product) => product.shopify_product_id === productId
                                ? {
                                    ...product,
                                    quantity: productQuantity - 1
                                }
                                : product)
                    }
                    : tenure);
                return newPreviousTenures;
            });
        }

    };

    const ShowProductModal = ( ) => {
        setAlert(false)
        setProduct(true)
    }

    const removeProduct = (product_id,mysteryBox) => {
        let productId = product_id
        if (mysteryBox) {
            setAlert(true);
            setAlertMessage("Product cant be removed with Mystery Box Plan");
        } else {     
            setTenureRecommendations((previousTenures) => {
                let newPreviousTenures = previousTenures.map((tenure) => tenure.tenure_index === tenureId
                    ? {
                        ...tenure,
                        products: tenure
                            .products
                            .filter((product) => product.shopify_product_id !== productId)
                    }
                    : tenure);
                return newPreviousTenures;
            });
            setAlert(true);
            setAlertMessage("Product was removed");
        }
    };

    if (products.length === 0) 
        return (
            <div>
                <AlertMessage setAlert={setAlert} showAlert={showAlert} message={alertMessage} variant="primary"/>
                <div className="d-flex h-100">
					<div className="align-self-center mx-auto">
                            <Button variant="light" onClick= { () => ShowProductModal() } className="addProduct" >
                                Add Products <IoAddCircle size={28}/>
                            </Button>
                    </div>
                </div>
                <ProductModal setProduct={setProduct} show={addProduct} tenureId={tenureId} />
            </div>
        );
    
    return (
        <div>
            <AlertMessage setAlert={setAlert} showAlert={showAlert} message={alertMessage} variant="primary"/>
            { products.length === 3 || mysteryBox === true ?
                null  
                :
                <div className="d-flex h-100">
                <div className="align-self-center mx-auto">
                    <Button variant="light" onClick= { () => setProduct(true) } className="addProduct">
                        Add Products <IoAddCircle size={28}/>
                    </Button>
                        
                </div>
                </div>
            }
            <br/>
            <div className="cart-card">
                <div className="row">
                    <div className="col-md-12 cart">
                        {/* <div className="title">
                            <div className="row">
                                <div className="col">
                                    <h4>
                                        <b>Products
                                        </b>
                                    </h4>
                                </div>
                                <div className="col align-self-center text-right text-muted">
                                    {totalPrice}
                                </div>
                            </div>
                        </div> */}
                        <div className="row border-top border-bottom">
                            {products.map((product, i) => (
                                <Row xs={2} md={3} className="row main align-items-center" key={i}>
                                    <Col sm>
                                        <span className="product-number">Product {i+1}
                                        {
                                            i+1 === 1 ?
                                            <span style={{color: "red"}}>*</span> :
                                            null
                                        }</span>

                                        {
                                            product["image"] === null ?
                                            <img
                                                className="img-fluid mobile"
                                                src={`${PUBLIC_IMAGE_PATH}default-image.jpg`}
                                                alt="product"/>
                                            :
                                            <img
                                                className="img-fluid mobile"
                                                src={product["image"]}
                                                alt="product"/>
                                        }
                                    </Col>
                                    <Col sm>
                                        <div className="row title-mobile">{product.title}</div>
                                        <div className="row text-muted price-mobile">{product.variants[0].title} - INR {product.variants[0].price * product.quantity}</div>
                                        <div className="row title-mobile">Quantity</div>
                                        <Row  xs="3" className="justify-content-md-center quantity">
                                            <Col sm>
                                                <Button size="sm" className="quantity-button" variant="light" onClick={() => decreaseQuantity(product.shopify_product_id, product.quantity,mysteryBox)}>
                                                <IoMdRemoveCircle/>
                                                </Button>
                                            </Col>
                                            <Col sm>
                                                <span className="border">{product.quantity}</span>
                                            </Col>
                                            <Col sm>
                                                <Button size="sm" className="quantity-button" style={{marginLeft: "-123%"}} variant="light" onClick={() => increaseQuantity(product.shopify_product_id, product.quantity,mysteryBox)}>
                                                    <IoAddCircle/>
                                                </Button>
                                            </Col>
                                        </Row>
                                        <br/>
                                    </Col>
                                    <Col sm>
                                        <Button className="rounded-pill" size="sm" variant="danger" onClick={() => removeProduct(product.shopify_product_id,mysteryBox)}>
                                            Remove
                                        </Button>
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <ProductModal setProduct={setProduct} show={addProduct} tenureId={tenureId} className="addProduct"/>
        </div>
    );
}
