import React ,{useEffect}from 'react'
import {Alert} from 'react-bootstrap';
export default function ({showAlert, setAlert, message, variant}) {
   

    useEffect(() => {
        const timer = setTimeout(() => {
            setAlert(false)
        }, 3000)

        return () => clearTimeout(timer);
      }, [showAlert,setAlert]);
      
    if (showAlert) {
        return (
            <Alert variant={variant} onClose={() => setAlert(false)} dismissible>
                {message}
            </Alert>
        );
    }
    return null;
}
