import React, {useContext, useState} from "react";
import {Form,Button,Row,Col} from "react-bootstrap";
import {ProductContext, AddProductContext} from "../../Helper/Context/ProductContext";
import { IoAddCircle } from 'react-icons/io5';
import { IoMdRemoveCircle } from 'react-icons/io';
import AlertMessage from "../AlertMessage/AlertMessage";

export default function AddProduct({tenureId,setProduct}) {
    const [showAlert,
        setAlert] = useState(false);
    const [alertMessage,
        setAlertMessage] = useState("");
    const [variantId,
        setVariantId] = useState(null);
    const close = () => setProduct(false);
    const [tenureProducts,
        setTenureProducts,
        setMoreRecommendations] = useContext(ProductContext);

    const [moreRecommendations] = useContext(AddProductContext);

    const buildNewProductObj = ({product, newVariant}) => {
        let productObj = product
        let variantObj = newVariant
        const newProductObj = {}
        newProductObj["shopify_product_id"] = productObj["shopify_product_id"]
        newProductObj["title"] = productObj["title"]
        newProductObj["quantity"] = productObj["quantity"]
        newProductObj["image"] = productObj["image"]
        newProductObj["created_at"] = productObj["created_at"]
        newProductObj["updated_at"] = productObj["updated_at"]
        newProductObj["variants"] = [variantObj]
        return newProductObj
    }

    const addProduct = (product, tenureId, variantId) => {
        const products = tenureProducts.find((tenure) => tenure.tenure_index === tenureId).products;
        const newVariant = product
            .variants
            .find((variant) => variant.shopify_variant_id === variantId)
        const nextProduct = buildNewProductObj({product, newVariant})
        const newProduct = products.map((product) => product.shopify_product_id).includes(product["shopify_product_id"]);
        if (variantId === null || newVariant === undefined) {
            setAlert(true);
            setAlertMessage("Please Select a Variant");
        } else {
            if (products.length === 3) {
                setAlert(true);
                setAlertMessage("Only 3 Products allowed");
            } else {
                if (newProduct) {
                    setAlert(true);
                    setAlertMessage("Product already addded");
                } else {
                    setTenureProducts((prevtenureProducts) => {
                        let newPreviousTenures = prevtenureProducts.map((tenure) => tenure.tenure_index === tenureId
                            ? {
                                ...tenure,
                                products: [
                                    ...tenure.products,
                                    nextProduct
                                ]
                            }
                            : tenure);
                        return newPreviousTenures;
                    });
                    close();
                }
            }
        }
    };

    const changeVariantId = (event,product) => {
        
        if (event.target.value === '') {
            setAlert(true);
            setAlertMessage("Please Select a Variant");
        } else {
            setVariantId(parseInt(event.target.value))
        }
    }

    const increaseQuantity = (product_id) => {
        const productId = product_id
        setMoreRecommendations(previousProducts => {
            let newprod = previousProducts.map((product) => product.shopify_product_id === productId
                ? {
                    ...product,
                    quantity: product.quantity + 1
                }
                : product)
            return newprod
        })
    }

    const decreaseQuantity = (product_id, quantity) => {
        const productId = product_id
        const productQuantity = quantity
        if (productQuantity >= 2) {
            setMoreRecommendations(previousProducts => {
                let newprod = previousProducts.map((product) => product.shopify_product_id === productId
                    ? {
                        ...product,
                        quantity: productQuantity - 1
                    }
                    : product)
                return newprod
            })
        }
    }

    return (
        <div>
            <div className="row border-top border-bottom">
                <AlertMessage setAlert={setAlert} showAlert={showAlert} message={alertMessage} variant="primary"/>
                {
                    moreRecommendations.length === 0 ?
                    <h4 style={{textAlign: "center"}}>There are no Recommendations</h4>
                :
                <>
                    {moreRecommendations.map((product, i) => (
                        <Row xs={1} md={3} className="row main align-items-center" key={i}>
                            <Col sm>
                                <img
                                    className="img-fluid"
                                    src={product["image"]}
                                    alt="product"/>
                            </Col>
                            <Col sm>
                                <div className="row">{product.title}</div>
                                <div className="row">Variant
                                <Form.Select
                                aria-label="Default select example"
                                className="rounded-pill"
                                onChange={(event) => changeVariantId(event,product)}>
                                <option value={product.variants[0].shopify_variant_id}>
                                    Select A Variant
                                </option>
                                {product
                                    .variants
                                    .map((variant) => (
                                        <option
                                            key={variant["shopify_variant_id"]}
                                            value={variant["shopify_variant_id"]}>{variant["title"]} INR {variant["price"] * product.quantity}
                                        </option>
                                    ))
                                }
                                </Form.Select>
                                </div>
                                {/* <div className="row">
                                    Price - INR { variantId === null ?
                                        product.variants.find((variant) => variant.shopify_variant_id === product.variants[0].shopify_variant_id).price * product.quantity 
                                        :
                                        product.variants.find((variant) => variant.shopify_variant_id === variantId).price
                                    }
                                </div> */}
                            </Col>
                            <Col sm>
                                <Button size="sm" variant="light" className="mobile-button-qnt" onClick={() => decreaseQuantity(product.shopify_product_id, product.quantity)}>
                                <IoMdRemoveCircle/>
                                </Button>
                                <Button size="sm" variant="light" className="mobile-button-qnt" disabled> {product.quantity} </Button>
                                <Button size="sm" variant="light" className="mobile-button-qnt"
                                    onClick={() => increaseQuantity(product.shopify_product_id, product.variants[0].shopify_variant_id)}>
                                    <IoAddCircle/>
                                </Button>
                                <Button className="rounded-pill add-product" variant="success" onClick={() => addProduct(product, tenureId, variantId)}>
                                    Add
                                </Button>
                            </Col>
                        </Row>
                    ))}
                </>
                }

            </div>
        </div>
    );
}