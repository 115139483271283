import React from "react";
import {Modal,Tab,Tabs} from "react-bootstrap";
import AddProduct from "../AddProduct/AddProduct";
import SearchProduct from "../SearchProduct/SearchProduct";
export default function ProductModal({show, setProduct, tenureId}) {
    const close = () => setProduct(false);

    return (
        <div>
            <Modal
                size="lg"
                show={show}
                scrollable={true}
                onHide={() => close()}
                aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Products
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs className="justify-content-center" defaultActiveKey="first">
                        <Tab eventKey="first" title="Recommended">
                            <AddProduct tenureId={tenureId} setProduct={setProduct} />
                        </Tab>
                        <Tab eventKey="second" title="All Products">
                            <SearchProduct tenureId={tenureId} setProduct={setProduct}/>
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>
        </div>
    );
}