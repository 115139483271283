import React from "react";
import Navbar from "../Components/Navbar/Navbar";
// import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
// import Card from "../Components/Card/Card";
import Footer from "../Components/Footer/Footer";
import Aboutus from "../Components/Aboutus/Aboutus";
import smoothscroll from "smoothscroll-polyfill";
function Home() {
    smoothscroll.polyfill();
    return (
        <div>
            <Navbar/>
            {/* <Breadcrumb/> */}
            {/* <Card/> */}
            <Aboutus/>
            <Footer/>
        </div>
    );
}

export default Home;
