import React, {useState} from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import "./Planbox.scss";
import Api from "../../Helper/api";
import Tenure from "../Tenure/Tenure";
import QnA from "../QnA/QnA";
import {ProductProvider} from "../../Helper/Context/ProductContext";
const PlanBox = ({plans, groupId}) => {
    const [planTenures,
        setPlanTenures] = useState({});
    const [showQnA,
        setShowQnA] = useState(false);
    const [showTenure,
        setShowTenure] = useState(false);

    const gotoTenure = () => {
        const divElement = document.getElementById('tenureandqna-section');
        divElement.scrollIntoView({ behavior: 'smooth', block: "start" });
    };

    async function fetchPlanTenures(planId, groupId) {
        
        const api = new Api();
        const data = await api
        .getPlan(planId, groupId)
        .then((response) => {
            const planTenuresData = response["data"];
            const qnaData = response["data"]["question_answer_present"];
                setPlanTenures(planTenuresData);
                return qnaData;
            })
            .catch((err) => console.log(err));
        show(data);

    }

    function show(data) {
        if (data) {
            displayQnA();
        } else {
            displayTenure();
        }
    }

    const displayTenure = () => {
        setShowTenure(true);
        setShowQnA(false);
        gotoTenure();
    };

    const displayQnA = () => {
        setShowTenure(false);
        setShowQnA(true);
        gotoTenure();
    };

    return (
        <ProductProvider>
            <Row xs={1} sm={1} md={2} lg={2} xl={4} className="d-flex justify-content-center align-items-center">
                {plans.map((plan) => (
                    <Col className="plan-center" key={plan.id}>
                        <Card className="plan-card mx-auto mb-3"
                            style={{}}>
                            <label>
                                <input
                                    type="radio"
                                    name="box"
                                    className="card-input-element"
                                    onClick={() => fetchPlanTenures(plan.id, groupId)}/>
                                <Card.Body className="planbox" id={`planbox-${plan.id}`}>
                                    <Card.Title className="plan-title">{plan.name}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">
                                        Free Delivery
                                    </Card.Subtitle>
                                    <Card.Text>{plan.description} </Card.Text>
                                    <div className="d-flex">
                                        <div className="align-self-center mx-auto">
                                            <Button variant="success" className="rounded-pill" active id="buttonId" onClick={() => fetchPlanTenures(plan.id, groupId)}>
                                                Select
                                            </Button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </label>
                        </Card>
                    </Col>
                ))}
            </Row>
            <br id="tenureandqna-section"/>
            <br />
            <div>
                {showTenure
                    ? <Tenure plan={planTenures}/>
                    : null}
                {showQnA
                    ? <QnA plan={planTenures} groupId={groupId}/>
                    : null}
            </div>
        </ProductProvider>
    );
};

export default PlanBox;
