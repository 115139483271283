import React, { useContext,useState } from "react";
import { Button, Container } from "react-bootstrap";
import PlanBox from "../Planbox/Planbox";
import "./Plan.scss";
import {SubscribeContext} from "../../Helper/Context/ShowSubscribeNow";
const Plan = ({ plans, group_id }) => {
	const [displayPlan, showPlans] = useState("none");
	const [displayStart, showStart] = useState(true);
	const [showSubcribe,setshowSubcribe] = useContext(SubscribeContext);

	function allPlan() {
		showPlans("block");
		showStart(false)
		gotoPlans();
		setshowSubcribe(false)
	}
	const gotoPlans = () => {
		const divElement = document.getElementById('plan-section');
        divElement.scrollIntoView({ behavior: 'smooth', block: "start" });
	};
	return (
		<>
			<div className="tenure-container" id="plan-section">
				<div className="d-flex h-100">
					<div className="align-self-center mx-auto">
					{ displayStart ? 
						<Button
						variant="success"
						className="rounded-pill"
						size="lg"
						onClick={() => allPlan()}
						>
							Start Here
						</Button>
					:
						null
					}
					</div>
				</div>
			</div>
			<div style={{ display: displayPlan }}>
				<Container id="showplan-section">
					<h3 style={{ textAlign: "center" }}>
						CHOOSE YOUR SUBSCRIPTION PLAN
					</h3>
					<PlanBox plans={plans} groupId={group_id}/>
					<br />
				</Container>
			</div>
		</>
	);
};

export default Plan;
