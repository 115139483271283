import React, {useContext, useState} from "react";
import {Accordion, Button} from "react-bootstrap";
import ShowProduct from "../ShowProduct/ShowProduct";
import {ProductContext} from "../../Helper/Context/ProductContext";
import "./ProductAccordion.scss";
import Checkout from "../Checkout/Checkout";
import AlertMessage from "../AlertMessage/AlertMessage";
import Api from "../../Helper/api";

export default function ProductAccordion({tenureInfo,mysteryBox}) {
    const [tenureRecommendations] = useContext(ProductContext);
    const [showAlert,
        setAlert] = useState(false);
    const [checkoutURL,
        setCheckoutURL] = useState("");
    const [alertMessage,
        setAlertMessage] = useState("");
    const [totalAmount,
        setTotalAmount] = useState(null)

    const totalProductAmount = tenureRecommendations.map((item) => {
        return item
            .products
            .map(({quantity, variants}) => {
                return quantity * variants[0].price
            })
            .reduce((price1, price2) => price1 + price2, 0)
    }).reduce((price1, price2) => price1 + price2, 0)

    const productsCheckout = (tenureRecommendations, tenureInfo,mysteryBox) => {
        const minAmount = parseInt(tenureInfo[3])
        const tenureId = parseInt(tenureInfo[2])
        const tenureDuration = parseInt(tenureInfo[0])
        const emptyProducts = tenureRecommendations.filter((products) => products.products.length === 0).length
        if (emptyProducts > 0) {
            setAlert(true)
            setAlertMessage("Atleast One Product Must Be Added In Every Month");
        } else if (totalProductAmount < minAmount) {
            setAlert(true)
            setAlertMessage("Total Price Must Be Above " + tenureInfo[3]);
        } else {
            setAlert(false);
            setTotalAmount(totalProductAmount)
            if (mysteryBox) {
                buildMysteryboxCheckoutParams(tenureId,tenureRecommendations,tenureDuration)
            } else {
                buildCheckoutParams(tenureId,tenureRecommendations)
            }
            gotoCheckout()
        }
    }

    const buildCheckoutParams = (tenureId,tenureRecommendations) =>{
        const checkout = []
        const checkoutParams = {}
        tenureRecommendations.map((tenureRecommendation) => {
            const newCheckoutObj = {}
            const newVarinat = []
            tenureRecommendation.products.map((product) => {
                const newProd = {}
                newProd['quantity'] = product['quantity']
                product.variants.map((variant) => {
                    return newProd['shopify_variant_id'] = variant['shopify_variant_id']
                })
                newVarinat.push(newProd)
                newCheckoutObj[tenureRecommendation.tenure_index] = newVarinat
            })
            return checkout.push(newCheckoutObj)
        })
        checkoutParams["tenure_id"] = tenureId
        checkoutParams["checkout"] = checkout
        return postCheckout(checkoutParams)
    }

    const buildMysteryboxCheckoutParams = (tenureId,tenureRecommendations,tenureDuration) => {
        const checkout = []
        const checkoutParams = {}
        tenureRecommendations.map((tenureRecommendation) => {
            const newCheckoutObj = {}
            const newVarinat = []
            tenureRecommendation.products.map((product,key) => {
                    const newProd = {}
                    newProd['quantity'] = product['quantity']
                    product.variants.map((variant) => {
                        return newProd['shopify_variant_id'] = variant['shopify_variant_id']
                    })
                    newVarinat.push(newProd)
                    newCheckoutObj[tenureRecommendation.tenure_index] = newVarinat
                    for(let i = 0; i < tenureDuration; i++){ 
                        checkout.push(newCheckoutObj)
                    }
                })
            return checkout
        })
        checkoutParams["tenure_id"] = tenureId
        checkoutParams["checkout"] = checkout
        return postCheckout(checkoutParams)
    }

    

    const postCheckout = (checkoutData) => {
        const api = new Api();
            api
            .checkout(checkoutData)
            .then((response) => {
                setCheckoutURL(response.data.checkout_url)
            })
            .catch((error) => error)
    }

    const gotoCheckout = () => {
        const divElement = document.getElementById('checkout-section');
        divElement.scrollIntoView({ behavior: 'smooth', block: "start" });
    }

    return (
        <div>
            <h3 style={{
                textAlign: "center"
            }}>SELECT YOUR TEAS</h3>
            <br/>
            <Accordion defaultActiveKey={["0"]}>
                {tenureRecommendations.map((tenure, i) => (
                    <Accordion.Item eventKey={String(i)} key={i}>
                        <Accordion.Header>
                            {mysteryBox === true ?
                                "Mystery Box"
                                :`Select Month ${tenure.tenure_index}`
                            }
                        </Accordion.Header>
                        <Accordion.Body>
                            <ShowProduct tenureId={tenure.tenure_index} mysteryBox={mysteryBox}/>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
            <AlertMessage setAlert={setAlert} showAlert={showAlert} message={alertMessage} variant="primary"/>
            <div className="d-flex h-100">
                <div className="align-self-center mx-auto">
                    <Button
                        variant="outline-success"
                        className="rounded-pill"
                        onClick={() => productsCheckout(tenureRecommendations, tenureInfo,mysteryBox)}
                        size="lg">Checkout</Button>
                </div>
            </div>
            <div id="checkout-section">
                {totalAmount !== null
                    ? <Checkout totalProductAmount={totalProductAmount} tenureInfo={tenureInfo} checkoutURL={checkoutURL}/>
                    : null
                }
            </div>
        </div>
    );
}