import React, { useContext,useEffect, useState} from "react";
import { Card, Button } from "react-bootstrap";
import "./Card.scss";
import { PUBLIC_IMAGE_PATH } from "../utils/Constants";
import Api from "../../Helper/api";
import { useParams } from "react-router-dom";
import Plan from "../Plan/Plan";
import {SubscribeContext} from "../../Helper/Context/ShowSubscribeNow";
function TopCard() {
	const { id } = useParams();
	const [groupName, setGroupName] = useState("");
	const [groupBanner, setBanner] = useState("");
	// const [groupBanner, setBanner] = useState(`${PUBLIC_IMAGE_PATH}SulamaniBanner.jpg`);
	const [groupDescription, setGroupDescription] = useState(
		""
	);
	const [plans, setPlans] = useState([]);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [showSubcribe] = useContext(SubscribeContext);	
	const webMainImage = `${PUBLIC_IMAGE_PATH}BannerAWeb.jpg`
	const mobileMainImage = `${PUBLIC_IMAGE_PATH}BannerAMob.jpg`

	const webImage = `${PUBLIC_IMAGE_PATH}BannerBWeb.jpg`
	const mobileImage = `${PUBLIC_IMAGE_PATH}BannerBMob.jpg`

	const BannerBWeb = windowWidth >= 650 ? webImage : mobileImage;
	const BannerAWeb = windowWidth >= 650 ? webMainImage : mobileMainImage;

	const fetchGroup = (id) => {
		const api = new Api();
		api.getGroupInfo(id)
		.then((response) => {
				const name = response["data"]["name"];
				const description = response["data"]["description"];
				const plansData = response["data"]["plans"];
				if (response['data']['banner'] === null) {
					setBanner(BannerAWeb)
				} else {
					setBanner(`${process.env.REACT_APP_UPLOAD_ENDPOINT}${response["data"]["banner"]["desktop_url"]}`)
				}
				setGroupName(name);
				setGroupDescription(description);
				setPlans(plansData);
			})
			.catch((err) => {
				window.location.href = "/404"
			});
	};

	const gotoServices = () =>{
		const divElement = document.getElementById('plans-section');
		divElement.scrollIntoView({ behavior: 'smooth', block: "start" });
	}
	useEffect(() => {
		fetchGroup(id);
		const handleWindowResize = () => {
			setWindowWidth(window.innerWidth);
		};
		window.addEventListener('resize', handleWindowResize);
		console.log(windowWidth);
		return () => {
			window.removeEventListener('resize', handleWindowResize);
		}
	}, [id,windowWidth]);

	return (
		<>
			<Card>
				<Card.Body>
					<h1 className="group-header">{groupName}</h1>
					<h5 className="group-description">{groupDescription}</h5>
					<div className="card-button">
						{
							showSubcribe ? 
							<Button
							className="rounded-pill btn btn-primary"
							size="lg"
							id="subscribe-now"
							onClick={gotoServices}
						>
							Subscribe Now
						</Button>
							:
							null
						}
						{/* { displayStart ? 
						<Button
						variant="success"
						className="rounded-pill"
						size="lg"
						onClick={() => allPlan()}
						>
							Start Here
						</Button>
					:
						null
					} */}
					</div>
				</Card.Body>
				<Card.Img
					variant="bottom"
					src={groupBanner}
				></Card.Img>
				<Card.Img
					variant="bottom"
					src={BannerBWeb}
				>
				</Card.Img>
			</Card>
			<br id="plans-section"/>
			<div >
				<Plan plans={plans} group_id={id}/>
			</div>
		</>
	);
}

export default TopCard;
