import React from "react";
// import MaxWidthWrapper from "../general/MaxWidthWrapper";
import { PUBLIC_IMAGE_PATH } from "../utils/Constants";
import { Nav, Navbar } from "react-bootstrap";
import "./Footer.scss";
const SOCIAL_LINKS = [
	{
		image: PUBLIC_IMAGE_PATH + "social-instagram.svg",
		alt: "Instagram",
		link: "https://www.instagram.com/teamonktea/",
	},
	{
		image: PUBLIC_IMAGE_PATH + "social-twitter.svg",
		alt: "Twitter",
		link: "https://twitter.com/teamonkglobal",
	},
	{
		image: PUBLIC_IMAGE_PATH + "social-facebook.svg",
		alt: "Facebook",
		link: "https://www.facebook.com/teamonktea",
	},
	{
		image: PUBLIC_IMAGE_PATH + "social-whatsapp.svg",
		alt: "Whatsapp",
		link: "https://www.linkedin.com/company/teamonk-global-foods-pvt-ltd/",
	},
	{
		image: PUBLIC_IMAGE_PATH + "social-youtube.svg",
		alt: "Youtube",
		link: "https://www.youtube.com/channel/UCBWkAO1OYtXjoFYmn-M_0rA",
	},
];

const PAYMENT_LINKS = [
	{
		image: PUBLIC_IMAGE_PATH + "payment-mastercard.png",
		alt: "MasterCard",
		link: "http://www.google.com",
	},
	{
		image: PUBLIC_IMAGE_PATH + "payment-visa.png",
		alt: "Visa",
		link: "http://www.google.com",
	},
	{
		image: PUBLIC_IMAGE_PATH + "payment-americanexpress.png",
		alt: "AmericanExpress",
		link: "http://www.google.com",
	},

	{
		image: PUBLIC_IMAGE_PATH + "payment-paypal.png",
		alt: "PayPal",
		link: "http://www.google.com",
	},
	{
		image: PUBLIC_IMAGE_PATH + "payment-paytm.png",
		alt: "Paytm",
		link: "http://www.google.com",
	},
];

export default function Footer() {
	return (
		<footer className={"footer-base"}>
			{/* <Container> */}
			<div className="footer-links-container">
				<ul className="footer-column">
					<li className="footer-logo">
						<Navbar.Brand href="#home" className="footer-brand">
					{" "}
					<img
						src={PUBLIC_IMAGE_PATH + "teamonklogov2white.png"}
						width="200px"
						height="55px"
						alt="Logo"
						style={{margin: "0% 0% 5% -5%"}}
					/>{" "}
				</Navbar.Brand>
					</li>
					<li className="footer-contact-option">
						<span className="footer-option-icon">
							<img
								src={PUBLIC_IMAGE_PATH + "footer-telephone.png"}
								width={19}
								height={19}
								alt="Telephone"
							/>
						</span>
						080 49577577
					</li>
					<li className="footer-contact-option">
						<span className="footer-option-icon">
							<img
								src={PUBLIC_IMAGE_PATH + "footer-phone.svg"}
								width={19}
								height={19}
								alt="Phone"
							/>
						</span>
						+91 81056 76665
					</li>
					<li className="footer-contact-option">
						<span className="footer-option-icon">
							<img
								src={PUBLIC_IMAGE_PATH + "footer-email.png"}
								width={19}
								height={19}
								alt="Email"
							/>
						</span>
						reachus@teamonk.com
					</li>
				</ul>

				<ul className="footer-column">
					<h3 className="footer-column-head">SHOP</h3>
					<Nav.Link className="footer-link" href="https://teamonk.com/collections/green-teas">Green Tea</Nav.Link>
					<Nav.Link className="footer-link" href="https://teamonk.com/apps/tracktor/track">Track Order</Nav.Link>
					<Nav.Link className="footer-link" href="https://teamonk.com/collections/white-teas">White Tea</Nav.Link>
					<Nav.Link className="footer-link" href="https://teamonk.com/collections/oolong-teas">Oolong Tea</Nav.Link>
					<Nav.Link className="footer-link" href="https://teamonk.com/collections/herbal-infusions">Herbal Infusions</Nav.Link>
					<Nav.Link className="footer-link" href="https://teamonk.com/collections/tea-gift-sets-online">Gifts & Accessories</Nav.Link>
				</ul>

				<ul className="footer-column">
					<h3 className="footer-column-head">ABOUT</h3>
					<Nav.Link className="footer-link" href="https://teamonk.com/pages/why-teamonk">Why Teamonk</Nav.Link>
					<Nav.Link className="footer-link" href="https://teamonk.com/pages/teamonk-story">Teamonk Story</Nav.Link>
					<Nav.Link className="footer-link" href="https://teamonk.com/pages/our-team">Our Team</Nav.Link>
					<Nav.Link className="footer-link" href="https://teamonk.com/blogs/press">Media</Nav.Link>
					<Nav.Link className="footer-link" href="https://teamonk.com/blogs/events">Events</Nav.Link>
					<Nav.Link className="footer-link" href="https://teamonk.com/pages/careers">Careers</Nav.Link>
				</ul>

				<ul className="footer-column">
					<h3 className="footer-column-head">BUSINESS</h3>
					<Nav.Link className="footer-link" href="https://teamonk.com/pages/corporate-gifting">Corporate Gifting</Nav.Link>
					<Nav.Link className="footer-link" href="https://teamonk.com/pages/business-enquiry">Business Orders</Nav.Link>
				</ul>

				<ul className="footer-column">
					<h3 className="footer-column-head">RESOURCES</h3>
					<Nav.Link className="footer-link" href="https://teamonk.com/blogs/featured">Blog</Nav.Link>
					<Nav.Link className="footer-link" href="https://teamonk.com/blogs/tea-science">Tea Matters</Nav.Link>
					<Nav.Link className="footer-link" href="https://teamonk.com/blogs/tea-recipes">Tea Recipes</Nav.Link>
					<Nav.Link className="footer-link" href="https://teamonk.com/blogs/food-pairing">Food Pairing</Nav.Link>
				</ul>

				<ul className="footer-column">
					<h3 className="footer-column-head">INFORMATION</h3>
					<Nav.Link className="footer-link" href="https://teamonk.com/pages/terms-of-use">Terms of Use</Nav.Link>
					<Nav.Link className="footer-link" href="https://teamonk.com/pages/privacy-policy">Privacy Policy</Nav.Link>
					<Nav.Link className="footer-link" href="https://teamonk.com/pages/return-policy">Return & Refund Policy</Nav.Link>
					<Nav.Link className="footer-link" href="https://teamonk.com/pages/faqs">FAQs</Nav.Link>
					<Nav.Link className="footer-link" href="https://teamonk.com/pages/contact-us">Contact Us</Nav.Link>
					<Nav.Link className="footer-link" href="https://teamonk.com/apps/tracktor/track">Track Order</Nav.Link>
				</ul>
			</div>

			<div className="footer-payments-container">
				<ul>
					{PAYMENT_LINKS.map((payment_link,i) => (
						<li
							className="footer-payment-icon-container"
							onClick={() =>
								window.open(payment_link.link, "_blank").focus()
							}
              key={i}
						>
							<img
								className="footer-payment-icon"
								src={payment_link.image}
								alt={payment_link.alt}
							/>
						</li>
					))}
				</ul>
			</div>

			<div className="footer-bottom-container">
				<div className="footer-trademark">
					© 2022, Teamonk , All rights reserved.
				</div>
				<ul className="footer-social-links">
					{SOCIAL_LINKS.map((social_link,i) => (
						<li
							className="footer-social-icon-container"
              key={i}
							onClick={() =>
								window.open(social_link.link, "_blank").focus()
							}
						>
							<img
								className="footer-social-icon"
								src={social_link.image}
								alt={social_link.alt}
								height={20}
							/>
						</li>
					))}
				</ul>
			</div>
			{/* </Container> */}
		</footer>
	);
}
