import React from "react";
import { Container } from "react-bootstrap";
import Home from "./Container/Home";
import { Switch, Route,Redirect } from "react-router-dom";
import "./App.scss";
import Nopage from "./Components/404page/404page";
function App() {
	return (
		<Container fluid>
			<Switch>
				<Route path={"/subscribe/:id"} component={Home} />
				<Route path="/subscribe" component={Home} />
				<Redirect exact from="/" to="/subscribe/1" />
				<Route path={"*"} component={Nopage}/>
				<Route path={"/404"} component={Nopage}/>
			</Switch>
		</Container>
	);
}

export default App;
