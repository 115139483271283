import React, {useContext, useState} from 'react'
import {Container, Row, Col, Card, Button} from "react-bootstrap";
import {ProductContext} from "../../Helper/Context/ProductContext";
import AlertMessage from '../AlertMessage/AlertMessage';

export default function Checkout({totalProductAmount, tenureInfo,checkoutURL}) {
    const [tenureRecommendations] = useContext(ProductContext);
    const [showAlert,
        setAlert] = useState(false);
    const [alertMessage,
        setAlertMessage] = useState("");

    const checkWithMinAmount = (checkoutURL) => {
        const minAmount = parseInt(tenureInfo[3])
        const emptyProducts = tenureRecommendations.filter((products) => products.products.length === 0).length
        if (emptyProducts > 0) {
            setAlert(true)
            setAlertMessage("Atleast One Product Must Be Added In Every Month");
        } else if (totalProductAmount < minAmount) {
            setAlert(true)
            setAlertMessage("Total Price Must Be Above " + tenureInfo[3]);
        } else {
            setAlert(false);
            checkoutToShopify(checkoutURL)
        }
    }

    const checkoutToShopify = (checkoutURL) => {
        window.open(checkoutURL, "_blank")
    }


    const discountAmount = (discountPercentage,totalProductAmount) => {
        return ((discountPercentage/ 100) * totalProductAmount).toFixed(2)
    }


    return (
        <div>
            <br/>
            <AlertMessage
                setAlert={setAlert}
                showAlert={showAlert}
                message={alertMessage}
                variant="primary"/>
            <Container className='tenure-container' id="showplan-section">
                <h3 style={{
                    textAlign: "center"
                }}>CHECKOUT</h3>
                <Card className="checkout-card">
                    <Row>
                        <Col md={6}>
                        <Card.Body>
                            <Card.Title> Total: &#8377; {totalProductAmount} only </Card.Title>
                            <Card.Subtitle>
                                <span style={{color: "green"}}>You Saved: &#8377; {discountAmount(tenureInfo[1],totalProductAmount)}</span>
                                <br/>
                                <br/>
                                ({tenureInfo[0]} Deliveries)
                            </Card.Subtitle>
                        </Card.Body>
                            {/* <Card.Subtitle>(3 Deliveries)</Card.Subtitle> */}
                        </Col>
                        <Col md={6} className="text-center">
                            <Button className="checkout-button" variant="success" size="lg" onClick={() => checkWithMinAmount(checkoutURL)}>Checkout</Button>
                        </Col>
                        <Card.Body>
                            {/* <Card.Subtitle>You can pay in installments at checkout with sezzle</Card.Subtitle> */}
                        </Card.Body>
                    </Row>
                </Card>
            </Container>
        </div>
    )
}
