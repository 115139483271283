import React from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { PUBLIC_IMAGE_PATH } from "../utils/Constants";
import "./Aboutus.scss";
function Aboutus() {
	return (
		<>
			<Container fluid>
				<Card className="text-center">
					<Card.Body
						style={{ padding: "3%" }}
						className="aboutus-card"
					>
						Your tea subscription is almost complete. When your
						first box arrives, you will be so pleased that you have
						joined a unique tea club. You'll also be tasting
						ethically sourced specialty tea that is often organic
						and has been purchased directly from the tea growers.
						Your support allows the farmer to invest in a
						sustainable crop and improve the quality of life for his
						children and community. In addition, our tea concierges
						regularly visit the estate or plantation where your tea
						is grown to ensure that everyone working on the farm,
						from the farmer to the picker, complies with
						international labor laws, social and environmental
						standards. We take you on a journey through the world of
						tea through our tea club.
						<br />
						<br />
						You will be pleasantly surprised by the distinct flavors
						and aroma. Month after month, your tea club subscription
						will continue to amaze you.
					</Card.Body>
				</Card>
			</Container>
			<Container>
				<Row className="mobile-view-img">
					<Col xs sm className="text-left">
						<div className="aboutus-details">
							<h3>How does it work?</h3>
							<br />
							<span className="heading">
								MAKE YOUR OWN PLAN (or) CHOOSE RECOMMENDED PLAN
								(or) PLAY THE QUIZ (DISCOVERY PLAN)
							</span>
							<br />
							<span className="description">
								Choose what you like or select from our curated
								plans. Your Tea Concierge selects perfect teas,
								just for you, from around the world.
							</span>
							<br />
							<br />
							<span className="heading">
								SENT ON YOUR SCHEDULE
							</span>
							<br />
							<span className="description">
								You choose the frequency of delivery and how
								much. Pause or increase any time. You're in
								control. And delivery is always free.
							</span>
							<br />
							<br />
							<span className="heading">DRINK HAPPY</span>
							<br />
							<span className="description">
								Enjoy perfect tea with family, friends or as a
								gift for someone special. Ethically sourced.
								Guaranteed to awaken your taste buds.
							</span>
						</div>
					</Col>
					<Col xs sm className="mobile-view" style={{margin: "-4%"}}>
						<img
							src={PUBLIC_IMAGE_PATH + "about-subscription.png"}
							className="aboutus-image"
							alt="about"
						/>
					</Col>
				</Row>
				<Row className="mobile-view-img">
					<Col className="mobile-view">
						<img
							src={PUBLIC_IMAGE_PATH + "Aboutus.png"}
							className="aboutus-image"
							alt="about"
							style={{paddingTop: "10%"}}
						/>
					</Col>
					<Col className="text-left">
						<div className="aboutus-details">
							<h3 className="heading">Why Choose Teamonk??</h3>
							<br />
							<li className="description">
								Tea is a daily habit, a subscription program
								takes away the hassle of buying teas every
								month.
							</li>
							<br />
							<li className="description">
								We deliver on a monthly basis to ensure that our
								teas are fresh and do not deteriorate on quality
								attributes on account of long storage.
							</li>
							<br />
							<li className="description">
								Selected for your personal preference, ethically
								sourced, freshly hand-packed, and delivered
								month after month.
							</li>
							<br />
							<li className="description">
								3 Free Delicious Tea Bags every month.
							</li>
							<br />
							<li className="description">
								Free Brewing Kit & Tea Timers.
							</li>
							<br />
							<li className="description">On-time delivery.</li>
							<br />
							<li className="description">
								Pause and continue subscription anytime.
							</li>
							<br />
							<h6 className="heading">Darjeeling Teas:</h6>
							Certified by Aditi Organic Certifications Pvt. Ltd.
							<br />
							NPOP/NAB/0017 Product produced and processed
							<br />
							According to NPOP & NOP.
						</div>
					</Col>
				</Row>
				<br />
				<Row>
					<Col className="text-left">
						<div className="">
							<h3 className="heading">
								Many more Exciting Features
							</h3>
							<span className="description">
								Let our connoisseurs take you on a journey and
								help you savour every single moment of each cup
								with a lovely tale and trails.
							</span>
						</div>
					</Col>
				</Row>
				<br />
				<Row className="text-center">
					<Col>
						<img
							src={PUBLIC_IMAGE_PATH + "feature1.png"}
							className=""
							alt="feature1"
							style={{paddingTop: "5%"}}
						/>
						<span className="text-center">
							<br />
							Product <br />
							Innovations
						</span>
					</Col>
					<Col>
						<img
							src={PUBLIC_IMAGE_PATH + "feature2.png"}
							className=""
							alt="feature2"
							style={{paddingTop: "5%"}}
						/>
						<span className="text-center">
							<br />
							Tea Pairing <br /> Sessions
						</span>
					</Col>
					<Col>
						<img
							src={PUBLIC_IMAGE_PATH + "feature3.png"}
							className=""
							alt="feature3"
							style={{paddingTop: "5%"}}
						/>
						<span>
							<br />
							Tea Trails & <br /> Garden Visits
						</span>
					</Col>
					<Col>
						<img
							src={PUBLIC_IMAGE_PATH + "feature4.png"}
							className=""
							alt="feature4"
							style={{paddingTop: "5%"}}
						/>
						<span>
							<br />
							Webinars &<br /> Masterclasses
						</span>
					</Col>
					<Col>
						<img
							src={PUBLIC_IMAGE_PATH + "feature5.png"}
							className=""
							alt="feature5"
							style={{paddingTop: "5%"}}
						/>
						<span>
							<br />
							Merchandise &<br /> Branding
						</span>
					</Col>
				</Row>
			</Container>
			<br />
			<Container fluid>
				<Card className="text-center">
					<Card.Body className="question-card">
						Got a Question? Take a Look at Our Faqs or Give Us a
						Call on +91-80-49577577
					</Card.Body>
				</Card>
			</Container>
			<br />
			<Container>
				<div className="">
					<h3>Frequently asked questions</h3>
					<br />
					<li className="heading"><b>Why subscribe from Teamonk?</b></li>
					{/* <br /> */}
					<span className="description">
						&nbsp;&nbsp; &#62; We are India's finest specialty tea company, bringing to
						you 100% pure & natural teas. We create our teas keeping
						your health and wellness in mind, and while we
						understand that health is found in nature, we make sure
						that our teas are not added with anything not natural,
						which means no oils, no added or artificial flavours,
						just pure and natural teas. Add to that, we have curated
						a unique tea experience in subscriptions that allows you
						to take your tea love to the next level.
					</span>
					<br />
					<br />
					<li className="heading">
						<b>How is Freedom Plan different from Wellness Plan?</b>
					</li>
					{/* <br /> */}
					<span className="description">
					&nbsp;&nbsp; &#62; Wellness Plan is a fixed plan which is designed for customers and addresses particular wellness needs: Weight Care, Detox, Glow, Relax, or Energy. Freedom Plan provides customers with the flexibility to choose amongst our teas, as per their preferences and wellness direction.
					</span>
					<br />
					<br />
					<li className="heading"><b>Is there a fee to join?</b></li>
					{/* <br /> */}
					<span className="description">
					&nbsp;&nbsp; &#62; No, there is no joining fee. You only pay for the
						product plan you choose.
					</span>
					<br />
					<br />
					<li className="heading"><b>What if I want to opt-out?</b></li>
					{/* <br /> */}
					<span className="description">
					&nbsp;&nbsp; &#62; If you wish to discontinue your subscription, please
						write to us at <a href="mailto:reachus@teamonk.com">reachus@teamonk.com</a> or call us at
						<a href="tel:+91-80-49577577">+91-80-49577577</a> <br/>and we will assist you.
					</span>
					<br />
					<br />
					<li className="heading">
						<b>Can I modify my subscription plan?</b>
					</li>
					{/* <br /> */}
					<span className="description">
					&nbsp;&nbsp; &#62; We understand that you might want to change your
						subscription plan at any point in time, and we are here
						to assist you. Kindly reach out to us and we are happy
						to help.
					</span>
					<br />
					<br />
					<li className="heading">
						<b>Do I have to pay at once, or is it pay-as-you-go?</b>
					</li>
					{/* <br /> */}
					<span className="description">
					&nbsp;&nbsp; &#62; You pay for the plan you choose, as a one time payment,
						so that you enjoy all the benefits of subscription right
						from the word go.
					</span>
					<br />
					<br />
					<li className="heading"><b> I have subscribed, now what?</b></li>
					{/* <br /> */}
					<span className="description">
					&nbsp;&nbsp; &#62; Once you have chosen and subscribed to a plan of your
						preference, we will ship your first subscription pack
						within 24-48 hours. Your subsequent packs will be
						shipped as per the schedule defined by you in your plan.
					</span>
					<br />
					<br />
				</div>
			</Container>
		</>
	);
}
export default Aboutus;
