import React, {createContext, useState} from 'react';

export const SubscribeContext = createContext();

export const SubscribeProvider = props => {
    const [showSubcribe,setshowSubcribe] = useState(true)

    return (
        <SubscribeContext.Provider value={[showSubcribe, setshowSubcribe]}>
             {props.children}
        </SubscribeContext.Provider>
    )
}